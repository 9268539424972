import { createHTTPClient } from '@elohello/http-client';
import { type QueryKey, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

function getQueryKey(): QueryKey {
  return ['access-token'];
}

function getQueryFn() {
  return async () => {
    const response = await createHTTPClient().url('/api/session').post().res();

    if (!response.headers.get('Content-Type')?.includes('application/json')) {
      // We were redirected to sign in, there is no session available
      return null;
    }

    const data = await response.json();
    return z.object({ accessToken: z.string() }).parse(data);
  };
}

export function useAccessToken() {
  return useQuery({
    queryKey: getQueryKey(),
    queryFn: getQueryFn(),
  });
}

useAccessToken.getQueryFn = getQueryFn;
useAccessToken.getQueryKey = getQueryKey;
