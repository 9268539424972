import { Outlet, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import './tailwind.css';
import { HTTPClientProvider } from '@elohello/api';
import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from '@remix-run/node';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
  dehydrate,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { useTracking } from 'react-tracking';
import { useDehydratedState } from 'use-dehydrated-state';
import { useAccessToken } from '~/features/api/use-access-token';
import { useProvideHttpClient } from '~/features/api/use-provide-http-client';
import { useProvideQueryClient } from '~/features/api/use-provide-query-client';
import { authenticator } from '~/services/auth.server';
import { themeSessionResolver } from '~/services/session.server';
import { ROUTES } from '~/utils/routes';
import { Layout } from './components/layout';
import { Toaster } from './components/sonner';

export async function loader({ request }: LoaderFunctionArgs) {
  const queryClient = new QueryClient();
  const session = await authenticator.isAuthenticated(request);
  const { getTheme } = await themeSessionResolver(request);

  if (session) {
    queryClient.setQueryData(useAccessToken.getQueryKey(), {
      accessToken: session.accessToken,
    });
  }

  return json({ dehydratedState: dehydrate(queryClient), theme: getTheme() });
}

export { Layout };

function AppWithHttpClient() {
  const { client } = useProvideHttpClient();

  return (
    <HTTPClientProvider client={client}>
      <Outlet />
    </HTTPClientProvider>
  );
}

export default function App() {
  const dehydratedState = useDehydratedState();
  const { queryClient } = useProvideQueryClient();
  const { Track } = useTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <CookiesProvider>
          <Track>
            <AppWithHttpClient />
            <Toaster richColors position="top-center" />
            <ReactQueryDevtools initialIsOpen={false} />
          </Track>
        </CookiesProvider>
      </HydrationBoundary>
    </QueryClientProvider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <div
      style={{
        padding: '16px',
        maxWidth: '400px',
        minHeight: '100vh',
        textAlign: 'center',
        fontFamily: 'Inter',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Whoops...</h2>
      <p style={{ lineHeight: 1.5 }}>
        We have encountered an unexpected error. It has been reported to our
        team. Use the button below to return to the app
      </p>
      <a
        href={ROUTES.FEED.BROWSE}
        style={{
          display: 'block',
          background: 'black',
          padding: '16px',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '8px',
        }}
      >
        Back to Feed
      </a>
    </div>
  );
}

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
];

export const meta: MetaFunction = () => [
  {
    property: 'og:title',
    content: '一peachy',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:description',
    content: 'Wkrocz do świata twórców',
  },
];
